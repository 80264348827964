import redApple from "../assets/red_apple.png";
import greenApple from "../assets/green_apple.png";

export const BonusType = {
  Debit: "debit",
  Credit: "credit",
};

export const initialValue = [
  { id: 10, name: "image", status: "", img: "/images/x.jpg" },
  { id: 11, name: "image", status: "", img: "/images/x.jpg" },
  { id: 2, name: "image", status: "", img: "/images/bomb.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 3, name: "image", status: "", img: "/images/money.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 5, name: "image", status: "", img: "/images/supergift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
  { id: 6, name: "image", status: "", img: "/images/gift.png" },
].sort(() => Math.random() - 0.5);

export const initialApples = [
  { id: 1, color: "red", src: redApple, items: "" },
  { id: 2, color: "red", src: redApple, items: "" },
  { id: 3, color: "red", src: redApple, items: "" },
  { id: 4, color: "red", src: redApple, items: "" },
  { id: 5, color: "green", src: greenApple, items: "" },
  { id: 6, color: "green", src: greenApple, items: "" },
  { id: 7, color: "green", src: greenApple, items: "" },
  { id: 8, color: "green", src: greenApple, items: "" },
];
