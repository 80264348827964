import React from "react";

const Card = React.memo(({ card, index, clickhandler }) => {
  const cardClassName = card.status ? "active" : "";

  return (
    <div
      className={`card ${cardClassName}`}
      onClick={() => clickhandler(index)}
    >
      <div className="card-overlay"></div>
      <img
        className="cartImage"
        src={card.status ? card.img : ""}
        alt={card.name}
      />
    </div>
  );
});

export default Card;
