import React from "react";
import coinImg from "../assets/coin.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ModalCartDetail = ({
  modalInfo,
  setModalInfo,
  handleChangeBonusCredit,
  balance,
}) => {
  const handleClose = () => {
    setModalInfo(modalInfo.show === false);
  };

  const handleChange = () => {
    if (balance >= modalInfo.item.price) {
      handleChangeBonusCredit(
        modalInfo.item.price,
        `Списання ${modalInfo.item.price} бонусів за обмін товару комбінацію`,
        modalInfo.item.id_prize
      );
      toast(
        `Ви обміняли ${modalInfo.item.price} бонусів на ${modalInfo.item.title}`
      );
    } else {
      toast(
        `У вас недостаттня кількість бонусів для  обміну, повертаєтесь в гру та заробляйте більше бонусів`
      );
    }
  };

  return (
    <div className="cartDetailContainer">
      <div className="cartDetailContent">
        <img
          className={`cartDetailImg  ${modalInfo.item.src} : cartSceleton`}
          src={modalInfo.item.src}
          alt="item"
        />
        <div className="contentBlock">
          <h4 className="cartDetailProductName">{modalInfo.item.title}</h4>
          <div className="cartDetailPriceBlock">
            <div className="priceBlock">
              <img src={coinImg} alt="coin" />
              <span className="cartDetailPrice">
                {modalInfo.item.price} ({modalInfo.item.price / 100} гривень)
              </span>
            </div>
          </div>
          <div className="cartDetailDescription">
            Цей товар ви можете обміняти на бонуси, які отримали у грі Вгадайка
          </div>
          <div className="cartDetailPriceBlock"></div>
          <div className="cartDetailContentButtonBlock">
            <button className="cartDetailContentBack" onClick={handleClose}>
              Назад
            </button>
            <button className="cartDetailContentButton" onClick={handleChange}>
              Обміняти
            </button>
          </div>
          <ToastContainer toastStyle={{ color: "#383838", fontSize: 15 }} />
        </div>
      </div>
    </div>
  );
};

export default ModalCartDetail;
