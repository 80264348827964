import coin from "../src/assets/coin.svg";
import Cards from "./Components/Cards";
import { useEffect, useState, useMemo } from "react";
import Modal from "./Components/Modal";
import BonusThree from "./Components/BonusThree";
import { auth, postBonusChange } from "./api/api";
import { useLocation } from "react-router-dom";
import dragon from "./assets/supergift.png";
import gift from "./assets/gift.png";
import money from "./assets/money.png";
import { BonusType, initialValue } from "./utils/constants";

function App() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const tree = searchParams.get("tree") || "";
  const telegram_id = searchParams.get("uid") || "";
  const phone = searchParams.get("phone") || "";
  const today = new Date();
  const todayString = today.toISOString().split("T")[0]; // Формат YYYY-MM-DD
  const attempt = useMemo(() => {
    return (
      JSON.parse(localStorage.getItem("attempt")) || {
        day: todayString,
        attempt: 4,
      }
    );
  }, [todayString]);

  useEffect(() => {
    if (!attempt) {
      // Якщо в локальному сховищі немає запису
      const newAttemptData = {
        day: todayString, // Сьогоднішня дата
        attempt: 4, // 4 спроби
      };
      localStorage.setItem("attempt", JSON.stringify(newAttemptData)); // Зберігаємо нові дані
      console.log("Немає спроб, зберігаємо:", newAttemptData);
    } else {
      const { day: attemptDate, attempt: attemptCount } = attempt;

      if (attemptDate === todayString) {
        // Якщо сьогоднішня дата
        console.log("Сьогоднішні спроби:", attemptCount);
      } else {
        // Якщо дата в локальному сховищі не сьогодні
        const newAttemptData = {
          day: todayString, // Оновлюємо дату на сьогодні
          attempt: 4, // Скидаємо на 4 спроби
        };
        localStorage.setItem("attempt", JSON.stringify(newAttemptData)); // Зберігаємо нові дані
        console.log("Перезаписуємо спроби на:", newAttemptData);
      }
    }
  }, [attempt]);

  const [bonus, setBonus] = useState(0);
  const [games, setGames] = useState(attempt.attempt);
  const [attempts, setAttempts] = useState(attempt.attempt <= 0 ? 0 : 4);
  const [message, setMessage] = useState("");
  const [matchCount, setMatchCount] = useState({});
  const [cards, setCards] = useState(initialValue);
  const [start, setStart] = useState(true);
  // const [bonusProduct, setBonucProduct] = useState(null);
  const [authData, setAuthData] = useState(null);
  const [updateBalance, setUpdateBalance] = useState(false);
  const [balance, setBalance] = useState(null);

  console.log(balance);

  useEffect(() => {
    if (window.Telegram) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.expand();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await auth({ phone, telegram_id, ref_phone: "" });
        setAuthData(res);
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, [updateBalance, authData?.balance]);

  console.log(authData, "data");

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    if (games === 0) {
      setMessage(
        "Уппс, ігри закінчились, приходьте завтра та забирайте більше бонусів"
      );
    }
  }, [games]);

  useEffect(() => {
    if (games === 0) {
      setTimeout(() => {
        setMessage(
          "Уппс, ігри закінчились, приходьте завтра та забирайте більше бонусів"
        );
      }, 2000);
    }
  }, [games, attempt]);

  const handleUpdateBalance = () => {
    setUpdateBalance(!updateBalance);
  };

  const restartGame = () => {
    if (attempt.attempt === 0) {
      if (window.Telegram) {
        window.Telegram.WebApp.close();
      }
      return;
    }
    setStart(true);
    if (games > 0) {
      setGames((prev) => prev - 1); // Зменшуємо кількість ігор
      const newAttemptData = {
        day: todayString, // Оновлюємо дату на сьогодні
        attempt: games - 1 || 0, // Використовуємо нову кількість спроб
      };
      localStorage.setItem("attempt", JSON.stringify(newAttemptData)); // Зберігаємо нові дані
      setAttempts(4); // Скидаємо кількість спроб
      setMatchCount({});
      setMessage("");

      // Скидаємо статус карток і перемішуємо їх
      const resetCards = initialValue.map((card) => ({
        ...card,
        status: "",
      }));
      const shuffledCards = shuffle(resetCards);
      setCards(shuffledCards);
    } else {
      setStart(false);
    }
  };

  const store = localStorage.getItem("attempts");
  console.log(store, "store");

  const handleChangeBonusCredit = async (qty, reason, sku) => {
    const sumAfterCredit = authData?.balance - qty;

    if (sumAfterCredit >= 0) {
      try {
        // Викликаємо postBonusChange і отримуємо відповідь
        const updatedBalance = await postBonusChange({
          id: authData?.id,
          qty,
          type: BonusType.Credit,
          reason,
          sku: sku || "", // Використовуємо переданий SKU або порожній рядок
        });

        console.log(updatedBalance, "updatedBalance");

        // Перевіряємо, чи отримали валідний результат
        if (updatedBalance) {
          setBalance(updatedBalance);
          handleUpdateBalance(); // Додаткове оновлення балансу, якщо потрібно
        } else {
          setMessage("Помилка: не вдалося оновити баланс.");
        }
      } catch (error) {
        // Виводимо повідомлення про помилку, якщо запит не вдався
        setMessage("Помилка при оновленні бонусів");
        console.error("Error updating bonus credits:", error);
      }
    } else {
      setMessage("Недостатня кількість бонусів");
    }
  };

  const handleChangeBonusDebit = async (qty, reason) => {
    const data = await postBonusChange({
      id: authData?.id,
      qty,
      type: BonusType.Debit,
      reason,
    });
    data();
    handleUpdateBalance();
  };

  const showWindow = tree === "true";

  return (
    <div className="mainContainer">
      {!showWindow && (
        <div>
          <div className="titleContainer">
            <h1 className="title">Вгадайка</h1>
            <div className="bonusBlock">
              <img className="coinImg" src={coin} alt="coin" />
              <h2 className="bonus">{authData?.balance}</h2>
            </div>
          </div>
          <div className="descriptionMain">
            <div>
              <div className="description-text">
                Відкриваючи картки, склади комбінацію з трьох однакових
                картинок, щоб отримати винагороду. Залежно від складеної
                комбінації можна отримати від 100 до 300 бонусів.
              </div>
            </div>
            <div>
              <div className="icon-container">
                <div className="item-block">
                  <img className="icon" src={money} alt="dragon" /> - 100 монет{" "}
                </div>
                <div className="item-block">
                  {" "}
                  <img className="icon" src={gift} alt="dragon" /> - 100 монет{" "}
                </div>
                <div className="item-block">
                  <img className="icon" src={dragon} alt="dragon" /> - 300 монет
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showWindow ? (
        <BonusThree
          balance={authData?.balance}
          handleChangeBonusCredit={handleChangeBonusCredit}
        />
      ) : (
        <>
          <Cards
            todayString={todayString}
            bonus={bonus}
            setBonus={setBonus}
            setCards={setCards}
            setMessage={setMessage}
            setMatchCount={setMatchCount}
            setAttempts={setAttempts}
            setGames={setGames}
            setStart={setStart}
            cards={cards}
            attempts={attempts}
            matchCount={matchCount}
            initialValue={initialValue}
            games={games}
            handleChangeBonusDebit={handleChangeBonusDebit}
            attempt={attempt}
          />
          {!start && (
            <Modal
              restartGame={restartGame}
              message={message}
              attempts={attempts}
              games={games}
              attempt={attempt}
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
