import React from "react";

const Modal = ({ restartGame, attempt, message, attempts, games }) => {
  return (
    <div className="modal-container" onClick={restartGame}>
      <div className="inside-modal-container">
        {message && <div className="messageMain">{message}</div>}
        <button className="restart">
          {games === 0 ? "Закрити" : "Почати"}
        </button>
      </div>
    </div>
  );
};

export default Modal;
