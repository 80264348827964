import React, { useEffect } from "react";
import three from "./../assets/transparent_three.png";
import { useState } from "react";
import ModalCartDetail from "./ModalCartDetail";
import wooden from "../assets/wood.png";
import coin from "../assets/coin.svg";
import { getBonusProduct } from "../api/api";
import { initialApples } from "../utils/constants";

const BonusThree = ({ balance, handleChangeBonusCredit }) => {
  const [apples, setApples] = useState(initialApples);
  const [isVisible, setIsVisible] = useState(true);
  const [bonusProduct, setBonusProduct] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    content: null,
    src: null,
  });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await getBonusProduct();
        setBonusProduct(response);
      } catch (err) {
        console.error(err);
      }
    };
    fetchProduct();
  }, []);

  const updateitemsPrice = () => {
    const updateItems = apples.map((apple, i) => {
      const prizeItem = bonusProduct[i % bonusProduct.length]; // Повторюємо bonusProduct циклічно
      if (prizeItem && prizeItem.qty > 0) {
        return {
          ...apple,
          items: prizeItem.image,
          id_prize: prizeItem.id,
          title: prizeItem.title,
          price: prizeItem.price,
        };
      }
      return apple;
    });
    setApples(updateItems);
  };

  useEffect(() => {
    if (bonusProduct) {
      updateitemsPrice();
    }
  }, [bonusProduct]);

  const handleClick = (i) => {
    console.log(i, "i");
    const apple = apples[i];
    if (apple.src === apple.items) {
      // Якщо src вже дорівнює items, показати модальне вікно
      setModalInfo({
        show: true,
        item: apple,
      });
    } else {
      // Інакше, змінити зображення
      const newApples = apples.map((item, index) =>
        index === i ? { ...item, src: item.items } : item
      );
      setApples(newApples);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible((prev) => !prev); // Змінюємо видимість
    }, 2000); // Змінюємо текст кожні 4 секунди

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {modalInfo.show ? (
        <ModalCartDetail
          modalInfo={modalInfo}
          setModalInfo={setModalInfo}
          handleChangeBonusCredit={handleChangeBonusCredit}
          balance={balance}
        />
      ) : (
        <div className="threeContainer">
          <div className="headerTree">
            <img className="wooden" src={wooden} alt="wooden" />
            <div className="titleBonusValue">
              <div className="titleBonus">Мої бонуси</div>

              <div className="titleValue">
                <img className="coinImg" src={coin} alt="coin" />
                <span>{balance}</span>
              </div>
            </div>
          </div>
          <div className="appleContainer">
            <img className="three" src={three} alt="three" />
            {apples.map((apple, index) => (
              <img
                key={apple.id}
                className={`apple ${apple.color}Apple${apple.id}`}
                src={apple.src}
                alt="apple"
                onClick={() => handleClick(index)}
              />
            ))}
          </div>
          <div className={`treeInfo ${isVisible ? "fade-in" : "fade-out"}`}>
            Hатисніть на яблуко щоб відкрити бонуси
          </div>
        </div>
      )}
    </>
  );
};

export default BonusThree;
